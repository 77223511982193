<script setup lang="ts">
withDefaults(defineProps<{
  dark?: boolean;
  hideIcon?: boolean;
  icon: Component;
  iconClass?: string;
}>(), { dark: false,hideIcon: false,iconClass: '', })

const iconClasses = computed(() => ([
  { 'text-white': __props.dark },
  { invisible: __props.hideIcon },
  __props.iconClass,
]));
</script>

<template>
  <button
    class="ui-floating-button group box-content border-0 bg-none outline-none transition-transform duration-300 hover:scale-110 hover:cursor-pointer [@media(pointer:coarse)]:flex [@media(pointer:none)]:flex"
    type="button"
  >
    <div
      class="floating-button-content relative flex size-full items-center justify-center after:absolute after:left-0 after:top-0 after:block after:size-full after:rounded-full after:backdrop-blur after:transition-colors after:content-['']"
      :class="[dark ? 'button-dark after:bg-dark/30 after:backdrop-brightness-90 after:backdrop-saturate-150 hover:after:!bg-dark/50' : 'after:bg-white/80 after:backdrop-brightness-125 after:backdrop-saturate-200 hover:after:!bg-white/100']"
    >
      <component
        :is="icon"
        class="icon z-10 size-5 text-dark"
        :class="iconClasses"
      />
    </div>
  </button>
</template>
